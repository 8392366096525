import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider, ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { setContext } from '@apollo/client/link/context';
import fetch from 'isomorphic-fetch';
import createStore from '@src/store';
import { fetchUser } from '@src/store/user';

import './src/assets/styles/index.scss';
import './src/assets/styles/global.css';

const datoCMS = new HttpLink({
  uri: process.env.GATSBY_DATO_CMS_URL,
  fetch,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${process.env.GATSBY_DATO_API_TOKEN}`,
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(datoCMS),
});

const queryClient = new QueryClient();

export const wrapRootElement = ({ element }) => {
  const store = createStore();
  store.dispatch(fetchUser());

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ApolloProvider client={client}>{element}</ApolloProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export const onInitialClientRender = () => {
  var isChromeBased = /Chrome/.test(navigator.userAgent);

  const sessionStorageKeys = Object.keys(sessionStorage).sort().reverse();
  const lastScrollPosKey = sessionStorageKeys.find((key) =>
    key.includes(`@@scroll|${window.location.pathname}`),
  );

  if (lastScrollPosKey) {
    const scrollY = sessionStorage.getItem(lastScrollPosKey);
    if (scrollY && !isNaN(scrollY)) {
      setTimeout(
        () => {
          window.scrollTo({
            top: parseFloat(scrollY),
            left: 0,
          });
        },
        isChromeBased ? 1000 : 0,
      );
    }
  }
};

// export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
// 	window.history.scrollRestoration = 'manual';
// 	const [leftPos, topPos] = getSavedScrollPosition(location) || [0, 0];

// 	setTimeout(() => {
// 		window.scrollTo({
// 			top: topPos,
// 			left: leftPos,
// 			// behavior: 'smooth',
// 		});
// 	}, 0);

// 	return false;
// };
